/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
    auth: {
        clientId: "d754248c-4344-4946-abf8-af17f8f99fe8", // This is the ONLY mandatory field that you need to supply.
        authority: "https://migrationsdashboard.b2clogin.com/migrationsdashboard.onmicrosoft.com/B2C_1_login_v1", // Choose SUSI as your default authority.
        knownAuthorities: ["migrationsdashboard.b2clogin.com"], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};


/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ['https://migrationsdashboard.onmicrosoft.com/pbi-embed-api/Token.Read'],
};

export const apiEndpoint = {
    embedTokenAPI: "https://pbi-embed-api.kinddesert-219e9324.germanywestcentral.azurecontainerapps.io/api/getEmbedToken"
};
